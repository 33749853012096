<template>
  <b-card>
    <b-overlay
        variant="white"
        rounded="sm"
        :show="!loaded">
      <div class="credits_head d-flex justify-content-between align-items-center">
        <div class="credits_count font-medium-4 font-weight-bold">{{ credits }} {{ $t('Credits') }}</div>
        <b-button :to="{ name: 'credits-payout'}"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
        >
          {{ $t('+ pay out Credits') }}
        </b-button>
      </div>
      <div class="credits_info pt-1">
        <p class="credits_statistic mb-0 font-medium-2 font-weight-bold">
          + {{ percentCalculate }} % ($ {{ calculateCurrency }})
        </p>
        <p class="deposits font-medium-1 pt-25 font-weight-normal">{{ $t('Deposits') }} : {{ deposits }} €</p>
      </div>
      <hr class="my-3 mx-2">
      <div class="statistic_monitor px-2">
        <div class="progress-wrapper">
          <b-card-text class="mb-25">
            {{ $t('Earned') }}: {{ earned }} €
          </b-card-text>
          <b-progress
              v-model="earned"
              max="65000"
              class="progress-bar-info"
              variant="info"
          />
        </div>
        <div class="progress-wrapper mt-1">
          <b-card-text class="mb-25">
            {{ $t('Duration') }}: {{ duration }} {{ $t('Months') }}
          </b-card-text>
          <b-progress
              v-model="duration"
              max="12"
              class="progress-bar-warning"
              variant="warning"
          />
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BButton,
  BCard,
  BOverlay,
  BProgress,
  BCardText

} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {mapGetters} from "vuex";

export default {
  name: "UpcomingEvents",
  components: {
    BCard,
    BOverlay,
    BButton,
    BProgress,
    BCardText
  },

  directives: {
    Ripple,
  },


  data() {
    return {
      duration: 6,
      earned: 6500,
      deposits: 3500,
      percentCalculate: 8.2,
      calculateCurrency: 250,
      credits: null,
      loaded: false,

    }
  }
  , mounted() {
    this.getCredits()
  },
  methods: {
    getCredits() {
      this.$http.get('/info/credits').then((response) => {
        this.credits = response.data.credit_amount
        this.loaded = true
      })
    }
  }

}
</script>
<style scoped lang="scss">
.credits_statistic {
  color: rgb(162, 198, 198);
}

.progress {
  height: 0.357rem;
}

</style>