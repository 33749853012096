<template>
  <div>
    <b-row>
      <b-col md="7">
        <UpcomingEvents/>
      </b-col>
      <b-col md="5">
        <Earnings/>
        <Credits/>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAlert,
  BAvatar,
  BBadge, BButton, BCard, BCardText,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect, BImg,
  BInputGroup, BInputGroupAppend, BOverlay,
  BPagination,
  BRow,
  BTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import UpcomingEvents from "@/views/dashboard/parts/UpcomingEvents";
import Credits from "@/views/dashboard/parts/Credits";
import Earnings from "@/views/dashboard/parts/Earnings";

export default {
  components: {
    Earnings,
    Credits,
    UpcomingEvents,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    BAlert,
    BCardText,
    BImg,
    vSelect,
  },
  data() {
    return {
      userData: null,
    }
  },
  methods: {},
  mounted() {
    this.userData = JSON.parse(window.localStorage.getItem('userData'))
  }


}
</script>
<style>
.circle-icon {
  background-color: rgb(127, 177, 176);
  box-shadow: 0 4px 29px 0 rgb(34 41 47 / 50%) !important;;
}

.card-header {
  background: #ad8a60;
}
[dir] .card .card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
</style>

