<template>
  <b-card :title="$t('Upcoming events') ">
    <b-overlay
        variant="white"
        :show="!loaded"
        rounded="sm"
    >
      <b-table
          class="position-relative"
          responsive
          primary-key="id"
          :items="items"
          :per-page="perPage"
          :current-page="currentPage"
          :fields="tableColumns"
          :sort-by.sync="sortBy"
          :filter="filter"
          show-empty
          :empty-text="$t('No upcoming events available')">
        <template #head()="data">
          {{ $t(data.label) }}
        </template>
        <template #cell(start_date)="data">
          {{ data.item.start_date|moment }}
        </template>
        <template #cell(actions)="data">
          {{ data.item.start_date|moment }}
        </template>
        <template #cell(actions)="data">
          <b-button
              :target="'_blank'"
              :to="{
                name: 'apps-bookings-futurebookings-details',
                params:{
                bookingid: data.item.id },
              }"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              type="submit"
              size="sm"
          >
            {{ $t('Event Room') }}
          </b-button>
        </template>
      </b-table>
    </b-overlay>
  </b-card>
</template>
<script>
import {
  BButton,
  BCard,
  BOverlay,
  BTable
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment from 'moment'

export default {
  name: "UpcomingEvents",
  components: {
    BCard,
    BOverlay,
    BButton,
    BTable
  },
  filters: {
    moment: function (date) {
      return moment.parseZone(date).format('DD.MM.YYYY HH:mm')
    }
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      items: [],
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      tableColumns: [
        {
          key: 'event.name',
          sortable: true,
          label: this.$i18n.t('Event'),
        },
        {
          key: 'start_date',
          sortable: true,
          label: this.$i18n.t('Date'),
        },
        {key: 'actions', label: this.$i18n.t('Actions'), class: 'table-action-buttons'}
      ],
    }
  }
  , mounted() {
    loaded: false,
        this.helperGetItems(`/experts/bookings`)

  }

}
</script>
<style scoped lang="scss">

@media screen and (max-width: 560px) {
  .event_item {
    flex-direction: column;

    .event_date {
    }
  }

}

.event_item {
  .event_date,
  .event_name {
    flex-basis: 33%;
  }
}

</style>
